import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../state/UserContext";
import { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { auth, firebaseFunctions } from "../users/firebase";
import { MoonLoader } from "react-spinners";
import logoKleurOpTransparant from "../assets/BNN_RGB_kleuroptransparant.svg"


export const AccountPage = () => {
  const navigate = useNavigate();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (user === undefined) {
      // Assume user context is still loading
      setIsLoading(true);
    } else if (!user) {
      // If no user is logged in, navigate to home page
      navigate("/");
    } else {
      // User is available, stop loading
      setIsLoading(false);
    }
  }, [navigate, user]);

  const joinCommunity = async () => {
    try {
      setIsLoading(true);
      const result = await firebaseFunctions.createPaymentLink();
      window.location.href = result.data.paymentLink;

      setIsLoading(false);
      setError("");
    } catch (error) {
      setError("Failed to join community. Please try again later");
    }
  };

  const leaveCommunity = async () => {
    if (
      window.confirm(
        "Weet je zeker dat je je abonnement wilt opzeggen? Je hebt in ieder geval toegang tot de volgende betaal cyclus"
      )
    ) {
      try {
        setIsLoading(true);
        await firebaseFunctions.leaveCommunity();
        setIsLoading(false);
        location.reload();
      } catch (error) {
        alert("Failed to cancel subscription. Please try again later");
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      try {
        setIsLoading(true);
        await firebaseFunctions.deleteUser();
        setIsLoading(false);
      } catch (error) {
        alert("Failed to delete your account. Please try again later");
      }
      auth.signOut();
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <MoonLoader color="white" />
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-darkblue text-lightgray">
      <Link to="/" className="absolute left-10 top-10 text-gray-400 hover:text-gray-200">
        <IoMdArrowRoundBack className="w-10 h-10" />
      </Link>
      <div className="flex flex-col gap-2 text-center p-6 bg-blue rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-[#03BF9C]">Hey, {user.firstName} {user.lastName}</h2>
        <p>Je email adres is: {user.email}</p>
        <p>Je telefoonnummer is: +{user.phoneNumber}</p>
        <p>Wil je gegevens wijzigen?</p>
        <p>stuur me een <a className="text-applegreen" href="https://wa.me/31616101365" target="_blank">appje</a> of <a className="text-applegreen" href="mailto:network@bimnerd.nl">e-mail</a></p>
        {!user.moneybirdSubscriptionId && (
          <button onClick={joinCommunity} className="bg-[#03BF9C] text-white py-2 px-4 rounded-lg hover:bg-[#04D88A] transition transform hover:scale-105">
            {user.isActiveCommunityMember ? "Abonnement heractiveren" : "Start abonnement"}
          </button>
        )}
        {user.isActiveCommunityMember && user.moneybirdSubscriptionId && (
          <button onClick={leaveCommunity} className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition transform hover:scale-105">
            Zeg mijn abonnement op
          </button>
        )}
        {error.length > 0 && <p className="text-red-500">{error}</p>}
        <button
          onClick={handleDelete}
          className="bg-gray-700 text-red-500 py-2 px-4 rounded-lg hover:bg-gray-600"
        >
          Verwijder mijn account
        </button>
        <div className="flex justify-center items-center">
          <img
            src={logoKleurOpTransparant}
            alt="BIMnerd Network Logo"
            style={{ height: 50 }}
          />
        </div>
      </div>
    </div >
  );
};
