import { signInWithEmailAndPassword } from "firebase/auth";
import { useCallback } from "react";
import { auth, firebaseFunctions } from "./firebase";
import MailChecker from "mailchecker";
import { RegisterError } from "../../../functions/shared/requests";

export const useFirebaseActions = () => {
  const loginUser = useCallback(async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      if (
        error.code === "auth/invalid-credential" ||
        error.code === "auth/user-not-found"
      ) {
        return { errorMessage: "Onjuiste combinatie van e-mail en wachtwoord" };
      }

      console.log(error);
      return { errorMessage: "Er is een fout opgetreden. Probeer het opnieuw" };
    }
  }, []);

  const registerUser = useCallback(
    async (
      email: string,
      password: string,
      phoneNumber: number,
      firstName: string,
      lastName: string
    ) => {
      if (!email || !password) {
        console.error("E-mail en wachtwoord zijn verplicht");
        return;
      }

      try {
        if (!MailChecker.isValid(email)) {
          return { errorMessage: "E-mail is niet geldig" };
        }

        await firebaseFunctions.registerNewUser({
          email,
          password,
          phoneNumber,
          firstName,
          lastName,
        });

        await signInWithEmailAndPassword(auth, email, password);
      } catch (error: any) {
        if ((error.details?.code as RegisterError) === "email-exists") {
          return { errorMessage: "E-mail is al geregistreerd" };
        }

        return { errorMessage: "Er is een fout opgetreden bij het registreren. Probeer het opnieuw" };
      }
    },
    []
  );

  return { loginUser, registerUser };
};
