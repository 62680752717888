// Main.tsx
import React, { useEffect, useState, } from "react";
import { firebaseFunctions } from "../users/firebase";
import { useUser } from "../state/UserContext";
import memeHangOut from "../assets/MemeHangout.jpg";
import meme99Problems from "../assets/Meme99problems.jpg"

const freeMemberCount = 200;
const freeMemberThreshold = 50;

interface MainProps {
  onRegisterClick: () => void;
}

export const Main: React.FC<MainProps> = ({ onRegisterClick }) => {
  const user = useUser();

  const [memberCount, setMemberCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchMemberCount = async () => {
      try {
        const result = await firebaseFunctions.getMemberCount();
        const count = result.data as number;
        setMemberCount(count);
      } catch (error) {
        console.error("Error fetching member count:", error);
        setMemberCount(null);
      }
    };

    fetchMemberCount();
  }, []);

  const freeMembersOpen = memberCount !== null && memberCount < freeMemberThreshold

  return (
    <main className="container mx-auto px-4 py-8 max-w-5xl">
      <h1 className="text-6xl text-center">Dé plek voor al je BIMvragen</h1>
      <section className="bg-blue p-6 rounded-lg shadow-md mt-6">
        <h2 className="font-bold text-applegreen mt-4">
          {
            memberCount !== null
              ? memberCount < freeMemberThreshold
                ? `De eerste ${freeMemberCount} aanmeldingen mogen de eerste 6 maanden gratis gebruikmaken van BIMnerd Network!`
                : memberCount <= freeMemberCount
                  ? `De eerste ${freeMemberCount} aanmeldingen mogen de eerste 6 maanden gratis gebruikmaken van BIMnerd Network! Nog ${freeMemberCount - memberCount} plekken beschikbaar!`
                  : `Al ${memberCount} BIMnerds zijn lekker bezig!`
              : "Loading nerd count..."
          }
        </h2>
        <p className="mt-6">Krijg toegang tot een WhatsApp Community waarin je al je vragen kunt stellen, kunt
          netwerken en kunt deelnemen aan discussies. En dat voor maar <span className="font-bold text-green">€ 5
            per maand</span>.
        </p>
        <p>Word lid en blijf up-to-date van wat er speelt!</p>
        <br />
        {user ?
          null :
          <button
            onClick={onRegisterClick}
            className="bg-applegreen hover:bg-green-600 text-white font-bold py-2 px-4 rounded no-underline"
            style={{ fontSize: '1.5rem' }}
          >
            "Wow, handig"
          </button>
        }
      </section>
      <section className="mt-10">
        <h2 className="font-bold">Voor jou een vraag, voor mij een weet</h2>
        <p className="mt-4">Er was eens … een BIMmer – zwetend aan het googelen en allerlei fora aan het afstruinen om antwoord te vinden op die ene vraag: ‘Hoe kan je in tekla bij export naar IFC het nulpunt veranderen?’ </p>
        <p className="mt-4">Ondertussen zit er aan de andere kant van het land een mede-BIMmer zorgeloos te genieten van zijn kop koffie, nadat hij net voor de zoveelste keer precies dat heeft gedaan waar zijn collega niet uitkomt – Tekla nulpunten aanpassen bij IFC export</p>
        <p className="mt-4">Och … hadden zij nou maar samen in een WhatsApp Community gezeten …</p>
      </section>

      <section className="mt-10">
        <h1 className="font-bold text-applegreen">Is het echt zo simpel? <em>Ja!</em></h1>
        <p className="mt-4">Het antwoord op jouw vraag zit in het hoofd van een andere BIMmer. Dus waarom zou je tijd verspillen aan het internet afspeuren, als je ook gewoon je vraag kan stellen aan zij die de antwoorden hebben? Precies! En met een flinke groep is er altijd wel iemand die je verder kan helpen.</p>
      </section>

      <section className="mt-10 bg-blue p-6 rounded-lg">
        <h3 className="mt-4">Dus zit je in de WhatsApp Community? Dan kun je …:</h3>
        <ul className="list-disc list-outside mt-4 space-y-4">
          <li><strong>Vragen stellen:</strong> Denk bijvoorbeeld aan ‘hoe maak ik in Solibri een clearance clash aan?’ of ‘welke cloudomgeving kan ik het beste gebruiken?’ of ‘hoe verzorg ik een goede BIM kick-off?’ Alles dus, zolang het maar iets met BIM te maken heeft.</li>
          <li><strong>Netwerken:</strong> Uch, vies woord – maar valt hier best mee. Stel je veel vragen en geef je anderen veel antwoorden? Dan val je op. Handig als je voor jezelf werkt of aan het rondkijken bent voor een nieuwe baan..</li>
          <li><strong>Trends volgen:</strong> In de appgroep zie je wat er speelt en kun je deelnemen aan discussies. Houdt je scherp!</li>
        </ul>
        {
          freeMembersOpen ? <p className="mt-4"><em>Je leest het goed:</em> gratis voor de eerste 200 aanmeldingen!</p>
            : null
        }
      </section>
      <section className="mt-10">
        <h1>Hoe werkt het?</h1>
        <h2 className="text-gray">Simpeler dan een Revitfile in de goede versie openen. </h2>
        <h3 className="font-bold mt-8">Dit is de mand versie</h3>
        <ul className="list-disc list-outside mt-3 space-y-1">
          <li>Je drukt op een van de knoppen op deze pagina</li>
          <li>Je vult je gegevens in en betaalt voor de eerste maand</li>
          <li>Je wordt toegevoegd aan de WhatsApp Community</li>
          <li>Je kiest 1 of meerdere subgroepen uit</li>
          <li>Je betaalt per maand en kan opzeggen wanneer je wil</li>
        </ul>
        {
          freeMembersOpen ? <p className="mt-4 text-sm">* De eerste 200 aanmeldingen krijgen de eerste 6 maanden gratis toegang. Je betaalt
            dan alleen € 0,15 als bewijs dat je bankrekening van jou is.</p>
            : null
        }
        <div className="space-y-4">
          <h3 className="mt-6">En de iets langere versie</h3>
          <p>… is eigenlijk nog steeds simpel. Je komt in een WhatsApp Community terecht waar alleen BIM wordt gesproken. Dus géén vakantiefoto’s of klaagberichten. En nee, Herbert, ook zeker geen GIFjes.</p>
          <p>Je stelt je vragen, helpt anderen door hun vragen te beantwoorden en neemt wanneer je daar zin in hebt deel aan specifieke gesprekken. Binnen de WhatsApp Community worden er subgroepen gemaakt, waarbij je zelf kunt kiezen aan welke je wil deelnemen.</p>
          <p>En mocht je nu denken: <em>pfff … krijg ik straks 128.012 meldingen per dag.</em> Welnee! Je kunt dus zelf in en uit subgroepen stappen en heel gemakkelijk de app op dempen zetten.</p>
        </div>
      </section>

      <section className="mt-10 space-y-4">
        <h1>€ 5? Waarom zo’n belachelijk lage prijs?</h1>
        <p>Nou ja, je mág ook meer betalen. Maar laten we eerlijk zijn, het is ook maar een WhatsApp Community.<br /> Wel megahandig en waardevol, dus daarom iedere cent waard, maar het moet ook laagdrempelig blijven.</p>
      </section>

      {
        freeMembersOpen ?
          <section className="mt-10 bg-applegreen p-4 rounded-lg selection:bg-blue selection:text-gray">
            <h1 className="mt-4 text-darkblue" >De eerste 200 aanmeldingen kunnen de eerste 6 maanden <em>gratis</em> deelnemen</h1>
            <h2 className="text-darkblue mt-2">Ja, je leest het goed: gratis!</h2>
            <p className="mt-2 text-darkblue">Waarom? Omdat dit helemaal nieuw is. Ik wil dus even het een en ander kunnen uitproberen en een fijne groep opbouwen. Pas na de 6 maanden ga je € 5 per maand betalen. Oh, en je kan opzeggen wanneer je wil!</p>
            <button onClick={onRegisterClick} className="bg-darkblue hover:bg-green-600 text-white font-bold py-2 px-4 rounded no-underline">Top! Ik wil er bij</button>
            <p className="italic text-darkblue">Meld je aan en je zit vandaag nog in de groep</p>

          </section>
          : null
      }

      <section className="mt-10 bg-blue p-6 rounded-lg text-white">
        <div className="w-full  flex flex-col md:flex-row">
          <div className="w-full md:w-2/3 space-y-4">
            <h1>Hey, ik ben die <a target="_blank" href="https://bimnerd.nl">BIMnerd</a></h1>
            <p>Ook wel Arjan voor insiders. En aangezien we straks samen in een WhatsApp Community zitten, kunnen we elkaar wel bij de voornaam noemen.</p>
            <p>Inmiddels hou ik me al meer dan een decennium bezig met BIM. Je kunt je voorstellen: dan komen er nog weleens wat vragen op.</p>
            <p>Maar omdat er geen centrale plek voor is, is het antwoord vinden ongeveer net zo moeilijk als aan je digibiele tante uitleggen wat voor werk je precies doet. Ain’t nobody got time for that.</p>
            <p>Maar toen dacht ik: wacht heel even. Met z’n allen weet je meer dan in je eentje – en meer dan Google kennelijk. Wat nou als we met z’n allen op WhatsApp gaan? Eureka!</p>
          </div>
          <div className="w-full md:w-1/3 hover:cursor-pointer">
            <img
              onClick={onRegisterClick}
              src={memeHangOut}
              alt="You like BIM to meme"
              className="object-cover rounded-lg w-full max-w-xs h-auto object-contain mx-auto mt-4"
            />
          </div>
        </div>
      </section>

      <section className="space-y-2 mt-10">
        <h1>Veelgegeven antwoorden op vragen over dit abonnement</h1>
        <p>‘Het is alleen voor Nederlandssprekende BIMmers.’</p>
        <p>‘Je kan gewoon opzeggen wanneer jij wil.’</p>
        <p>‘Nee, het is niet de bedoeling om vacatures of bedrijfspromotie in de WhatsApp Community te gooien. Dat is een gelekaartsituatie, 2 keer en je ligt er uit.’</p>
        {freeMembersOpen ? <p>‘Jaaaaa, de eerste 6 maanden krijg je écht gratis als je bij de eerste 200 hoort.’</p> : null}
        <p>‘Alle vragen die over BIM gaan. npmDus niet alleen over Solibri, Revit of AutoCAD, maar ook bijvoorbeeld of dat het kwaad kan als de architect bij het DO wil beginnen met een BIM-model.’</p>
        <p>‘Ja, zou top zijn als je een review voor me wil schrijven zodra je het een paar weken hebt uitgeprobeerd! Mail je me dan?’</p>
        <p>‘Nee, Herbert, echt geen GIFjes en vakantiekiekjes.’</p>
        <p>‘Ja wel BIMmemes Buddy, maar alleen in de memes subgroep, of als ze super relevant zijn in de discussie. Het moet wel leuk blijven.’</p>
        <p>‘Als je een andere vraag hebt, kun je mailen naar: <a href="mailto:network@bimnerd.nl">network@bimnerd.nl</a>.’</p>
        <p>‘Ja, we behandelen elkaar normaal anders lig je er sneller uit dan je shared-wall-based-generic-model kunt zeggen’.</p>
      </section>

      <section className="mt-10 text-center">
        <h3 className="text-xl font-bold text-applegreen">Klaar om het BIMleven een stuk makkelijker te maken?</h3>
        <div className="flex items-center justify-center mt-6">
          <img src={meme99Problems} alt="99 problems but BIM aint one meme" className="object-cover rounded-lg w-full max-w-xs h-auto object-contain" />
        </div>
        <p className="mt-4">Supertof als je erbij komt, ik kijk ernaar uit je in de groep te verwelkomen.</p>
        <button onClick={onRegisterClick} className="mt-4 bg-applegreen hover:bg-green-600 text-white font-bold py-2 px-4 rounded no-underline">Ik wil in die
          groep!</button>
      </section>


    </main>
  );
};
